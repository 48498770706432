<template>
  <div class="registration">
    <navbar-component class="justify-center" />
    <div class="registration-wrapper d-flex">
      <div class="registration-left">
        <div class="img-wrapper">
          <img src="~@/assets/imgs/registration.svg" alt="side img" />
        </div>
      </div>
      <div class="registration-rigth">
        <h2 class="registration-title">{{ $t('registration') }}</h2>
        <form class="form" @submit.prevent="registration">
          <div class="form__input">
            <label class="semi-16 color-black">{{ $t('name') }}</label>
            <input
              type="text"
              class="color-gray-warm"
              :placeholder="$t('name')"
              v-model="postObject.name"
              autocomplete="off"
            />
          </div>
          <div class="form__input" :class="{ invalid: !isEmail }">
            <label class="semi-16 color-black">
              {{ $t('emailAddress') }}
            </label>
            <input
              type="text"
              class="color-gray-warm"
              @input="checkEmail"
              :placeholder="$t('yourEmailAddress')"
              v-model="postObject.email"
              autocomplete="off"
            />
          </div>
          <div class="form__input">
            <label class="semi-16 color-black">
              {{ $t('country') }}
            </label>
            <select
              required
              class="normal-14 color-black-light"
              v-model="postObject.country_id"
            >
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="form__input">
            <label class="semi-16 color-black">{{ $t('password') }}</label>
            <input
              type="password"
              class="color-gray-warm"
              :placeholder="$t('yourPassword')"
              @input="checkPassword"
              v-model="postObject.password"
              autocomplete="off"
            />
            <div class="form__message" v-if="showMessage">
              <p
                :class="{ invalid: !conditions.length }"
                class="regular-16 color-green"
              >
                {{ $t('minChar') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasLowercase }"
                class="regular-16 color-green"
              >
                {{ $t('atLeasetLowercase') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasUppercase }"
                class="regular-16 color-green"
              >
                {{ $t('atLeasetUppercase') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasNumber }"
                class="regular-16 color-green"
              >
                {{ $t('atLeastNumber') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasSpecialChar }"
                class="regular-16 color-green"
              >
                {{ $t('atLeastspecial') }}
              </p>
            </div>
          </div>
          <div class="form__input" :class="{ invalid: !isSamePassword }">
            <label class="semi-16 color-black">
              {{ $t('confirmPassword') }}
            </label>
            <input
              type="password"
              :placeholder="$t('confirmPassword')"
              class="color-gray-warm"
              @input="samePassword"
              autocomplete="off"
            />
          </div>
          <checkbox-component
            :label="$t('termsOfPolicy')"
            url="/privacy-policy"
            :urlText="$t('privacyNotice')"
            v-model="privacyNotice"
            :inputValue="true"
          />
          <button
            class="form__submit semi-16 color-white"
            type="submit"
            :disabled="!canSubmit"
          >
            {{ $t('registration') }}
          </button>
        </form>
        <p class="registration-forgot semi-16 color-gray-dark">
          {{ $t('haveAccount') }}
          <router-link to="/login" class="color-red">
            {{ $t('login') }}
          </router-link>
        </p>
        <div class="auth-language">
          <LanguageSelector />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent';
import CheckboxComponent from '@/components/CheckboxComponent/CheckboxComponent';
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue';
import api from '@/api';
import { mapActions } from 'vuex';

export default {
  name: 'RegistaionView',
  components: {
    NavbarComponent,
    CheckboxComponent,
    LanguageSelector,
  },
  data() {
    return {
      isEmail: true,
      isSamePassword: true,
      privacyNotice: false,
      countries: [],
      toastOption: {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      },
      postObject: {
        email: null,
        password: null,
        name: null,
        country_id: null,
      },
      conditions: {
        length: true,
        hasSpecialChar: true,
        hasUppercase: true,
        hasLowercase: true,
        hasNumber: true,
      },
    };
  },
  mounted() {
    this.fetchCountries();
  },
  methods: {
    ...mapActions(['updateUserLoggedIn']),
    async fetchCountries() {
      await api.countries().then((data) => {
        if (data.data.data) {
          this.countries = data.data.data;
        }
      });
    },
    async registration() {
      if (!this.canSubmit) {
        this.$toast.error(this.$t('formError'), this.toastOption);
        return;
      }
      const postBody = {
        name: this.postObject.name,
        email: this.postObject.email,
        password: this.postObject.password,
        password_confirmation: this.postObject.password,
        country_id: this.postObject.country_id,
        user: {
          service: {
            login_uri: `${window.location.origin}/login`,
          },
        },
      };
      await api.getCookie();
      const res = await api.userRegistration(postBody);
      console.log(res);
      const { data } = res;
      const { messages, code } = data;
      if (code === 422) {
        const { error } = messages;
        this.$toast.error(error, this.toastOption);
        return;
      }
      const { general } = messages;
      this.$toast.success(general, this.toastOption);
      localStorage.setItem('confirmEmail', btoa(this.postObject.email));
      this.$router.push({ name: 'ComfirmEmail' });
    },
    checkPassword({ target: { value } }) {
      const lowerCaseLetters = /[a-z]{1,}/g;
      const upperCaseLetters = /[A-Z]{1,}/g;
      const numbers = /[0-9]{1,}/g;
      const specialCharacters = /[!@#$%^&*(),.?":{}|<>+-]{1,}/g;
      this.conditions.hasSpecialChar = specialCharacters.test(value);
      this.conditions.hasNumber = numbers.test(value);
      this.conditions.hasUppercase = upperCaseLetters.test(value);
      this.conditions.hasLowercase = lowerCaseLetters.test(value);
      this.conditions.length = value.length >= 8;
    },
    checkEmail({ target: { value } }) {
      // eslint-disable-next-line no-useless-escape
      const email = /^[^@]+@[\w\-\+]+(\.[\w\-\+]+)+$/;
      this.isEmail = email.test(value);
    },
    samePassword({ target: { value } }) {
      this.isSamePassword = value === this.postObject.password;
    },
  },
  computed: {
    showMessage() {
      return !Object.keys(this.conditions).every(
        (key) => this.conditions[key] === true
      );
    },
    canSubmit() {
      return (
        Object.keys(this.conditions).every(
          (key) => this.conditions[key] === true
        ) *
        this.isEmail *
        this.isSamePassword *
        this.privacyNotice
      );
    },
  },
};
</script>

<style lang="scss" src="./RegistaionView.scss" scoped></style>
